
.app__services_content{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.app__services_content-row{
    display: flex;
    margin-bottom: 10px;
}

.app__services_content-service{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 50%;
    margin:15px;
}

.app__services_content-row_text{
    padding: 5px 10px;
}

.app__services_content-row_img{
    max-width: 15px;
    max-height: 75px;
    min-width: 150px;
    min-height: 75px;
    margin:0 20px;
}

.app__services_content-row_img img{
    width: 100%;
    height: 100%;
}



/* Media Queries  */
@media screen and (max-width : 850px){
    .app__services_content-row{
        flex-direction: column;
    }
    .app__services_content-service{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-base);
        /* text-align: center; */
    }
    .app__services_content-row_img{
        margin-bottom: 80px;
    }
}