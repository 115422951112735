.section__padding{
  padding: 4rem 6rem;
}

.app__bg{
  background:url('./assets/bg.png') ;
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
}

.flex__center{
  display: flex;
  align-items: center;
  justify-content: center;
}


.app__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}

.app__wrapper_info{
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.app__wrapper_img{
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app__wrapper_img img{
  width: 90%;
  height: 100%;
}

.app__heading{
  font-family: var(--font-base);
  font-weight: 800;
  font-size: 44px;
  line-height: 64px;
  text-transform: capitalize;
  color: var(--color-base);
  letter-spacing: 0.04em;
}

.app__paraHeading{
  font-family: var(--font-alt) ;
  font-weight: bolder;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-alt);
  text-transform: capitalize;
  letter-spacing: 0.04em;
}

.app__para{
  font-family: var(--font-alt) ;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-black);
  text-transform: capitalize;
  letter-spacing: 0.04em;
}


/* Button  */
.button{
  background-color: var(--color-base);
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  padding: 0.35rem 1rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Text Animation  */

.animate-charcter
{
  background-image: linear-gradient(
    +225deg,
    #264796 0%,
    #070707 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 30s linear infinite;
  /* display: inline-block; */
}

.app__products_product{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}




/* Media Queries  */
@media screen and (min-width : 2000px){
  .app__heading{
    font-size: 100px;
    line-height: 110px;
  }
  .app__paraHeading{
    font-size: 37px;
    line-height: 67px;
  }
  .button , .app__para{
    font-size: 30px;
    line-height: 50px;
  }
}

@media screen and (max-width : 1150px){
  .app__wrapper{
    flex-direction: column;
  }
  .app__wrapper_img{
    margin-top: 5rem;
  }
  .app__wrapper_img img{
    width: 100%;
  }
}

@media screen and (max-width : 850px){
  .section__padding{
    padding: 4rem;
  }
}

@media screen and (max-width : 550px){
  .section__padding{
    padding: 4rem 2rem;
  }
  .app__heading{
    font-size: 30px;
    line-height: 50px;
  }
  .app__paraHeading{
    font-size: 20px;
  }
  .app__para{
    font-size: 13px;
  }
}