.app__productcard{
    /* w300 h220  */
    width: 180px;
    height: 180px;
    border-radius: 0.4em;
    /* box-shadow: 0.3em 0.3em 0.7em #00000015; */
    transition: border 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border: var(--color-alt) 0.5px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 12px 5px;
    margin: 0.5rem;
    background-color: rgb(241, 239, 239)
}

.app__productcard img{
    width: 99%;
    max-height: 86%;
    min-height: 86%;
    /* padding: 0 2px; */
    background-size: cover;
    border-radius: 0.4em;
}

@media screen and (max-width : 1150px){
    .app__productcard{
        margin: 4px auto;
    }
}