@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;800&family=Quantico&family=Sono:wght@200;400;600;800&display=swap');

:root{
  --font-base : 'Overpass', sans-serif;
  --font-alt : 'Quantico', sans-serif;

  --color-base : #264796;
  /* --color-alt : #FF6E31; */
  --color-alt : #ff0062;
  --color-bgOne : #FFEBB7;
  --color-bgTwo : #AD8E70;
  --color-black : #000;
  --color-white : #fff;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a{
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
