.app__footer{
    margin: 1px 1px 0px 1px;
    background-color: var(--color-bgOne);
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    padding: 20px;
}
.app__footer-section-One{
    flex: 2.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1px;
}
.app__footer-section-One div{
    margin-top: 10px;
}
.app__footer-section_social{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app__footer-section{
    flex: 1;
    margin: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.app__footer-section_img{
    width: 200px;
}

.app__footer-section_img img{
    width: 100%;
}



/* Media Queries  */

@media screen and (max-width : 850px){
    .app__footer{
        flex-direction: column;
    }
}