.app__clipart_section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.app__clipart_svg {
  width: 130px;
  height: 130px;
  /* box-shadow: 0.3em 0.3em 0.7em #00000015; */
  margin: 0.3rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.app__clipart_svg div{
    min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;  
}

.app__clipart_svg img {
  min-width: 90%;
  min-height: 90%;
  max-width: 90%;
  max-height: 90%;
  border-radius: 30%;
  border: 2px solid var(--color-base);
  padding: 0.6rem;
}

.app__clipart_svg p{
    color: var(--color-base);
    text-align: center;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 600;
    
}


/* Media Queries  */

@media screen and (max-width : 1150px){
    .app__clipart_svg{
        margin: 21px;
    }
}
