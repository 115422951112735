.app__contact_container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__contact_container-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px;
}

.app__contact_container-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 3px;
    padding: 20px 5px;
    background-color: var(--color-bgOne);
    text-align: center;
    flex-basis: 48.5%;
    min-height: 200px;
}

.app__contact_container-section span{
    display: inline;
}

.app__contact_container-right{
    flex: 1;
    /* background: rgb(0,212,255);
    background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(240,248,255,1) 35%, rgba(240,248,255,1) 100%); */
    background-color: var(--color-bgOne);

}

.app__contact_container-right form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 365px;
    margin: 25px 50px;
}

.app__contact_container-right form input, textarea{
    width: 100%;
    padding: 10px;
    /* color: var(--color-base); */
    font-size: 15px;
    border: 1px solid var(--color-base);
}

.app__contact_container-right_submit{
    background-color: var(--color-base);
    color: var(--color-white) ;
    cursor: pointer;
}





/* Media Queries  */
@media screen and (max-width : 1150px){
    .app__contact_container{
        flex-direction: column;
    }
    .app__contact_container-left{
        min-width: 100%;
        
    }
    .app__contact_container-section{
        flex-basis: 25%;
        margin: 3px 0px;
        padding:0px;
        /* min-width: 120px; */
        min-width: 48%;
        min-height:300px;
        justify-content: space-evenly;
    }
    .app__contact_container-right{
       min-width: 100%;
    }
    .customize{
        font-size: 11px;
    }
    .app__contact_container-section span{
        display: block;
    }
}

/* @media screen and (max-width : 450px){
    .app__contact_container-section{
        max-width: 100px;
    }
} */