.app__about{
    margin-top: 50px;
    background-color:var(--color-bgOne);
}

.app__paraHeading{
    margin-top: 10px;
}

.app__about_goals{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.app__about_goal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    box-shadow: 1px 2px var(--color-alt);
    background-color: var(--color-white);
    padding: 4px;
    text-align: center;
}

.app__about_goal img{
    width: 60px;
    height: 60px;
    margin: 5px;
}


/* Media Queries  */

@media screen and (max-width : 850px){
    .app__about_goals{
        flex-direction: column;
    }
}
